<template>
  <a-spin :spinning="loading">
    <a-card title="线上版本" class="mb20">
      <p class="s_text_c p-t-60 p-b-60 s_c999" v-if="!releaseVersion.id">你暂无线上版本</p>
      <div class="s_flex_bian_c" v-else>
        <div class="width10">
          <p class="s_c999">版本号</p>
          <p class="fz-22">{{releaseVersion.user_version}}</p>
        </div>
        <div class="width80">
          <div class="fz-14 m-b-5 s_flex_ali"><p class="s_c999 width-100">发布时间</p>{{releaseVersion.create_time}}</div>
          <div class="fz-14 s_flex_ali"><p class="s_c999 width-100">项目备注</p>{{releaseVersion.user_desc}}</div>
        </div>
        <div class="width10">
          <a-button type="primary" v-has:editionBack="back">版本回退</a-button>
        </div>
      </div>
    </a-card>
    <a-card title="审核版本">
      <p class="s_text_c p-t-60 p-b-60 s_c999" v-if="!auditVersion.id"> 你暂无提交审核的版本或者版本已发布上线</p>
      <div class="s_flex_bian_c" v-else>
        <div class="width10">
          <p class="s_c999">版本号</p>
          <p class="fz-22 m-b-5">{{auditVersion.user_version}}</p>
          <a-tag color="orange" v-if="auditVersion.audit_status==0">审核成功</a-tag>
          <a-tag color="orange" v-if="auditVersion.audit_status==1">审核拒绝</a-tag>
          <a-tag color="orange" v-if="auditVersion.audit_status==2">审核中</a-tag>
          <a-tag color="orange" v-if="auditVersion.audit_status==3">已撤回</a-tag>
          <a-tag color="orange" v-if="auditVersion.audit_status==4">审核延后</a-tag>
        </div>
        <div class="width80">
          <div class="fz-14 m-b-5 s_flex_ali"><p class="s_c999 width-100">提交时间</p>{{auditVersion.update_time}}</div>
          <div class="fz-14 s_flex_ali"><p class="s_c999 width-100">项目备注</p>{{auditVersion.user_desc}}</div>
        </div>
        <div class="width10">
          <a-button type="primary" v-has:editionRelease="release" v-if="auditVersion.audit_status==0">
            发布
          </a-button>
        </div>
      </div>
    </a-card>
  </a-spin>
</template>
<script>
  import { getAppletEdition, miniappRevert } from "@/api/appletCode";
  import { inject, onMounted, reactive, toRefs } from 'vue'
  import { $iscode } from '../../../../utils/app';
  export default {
    setup() {
      const $Modal = inject('$Modal')
      const state = reactive({
        detail: {},
        releaseVersion: {},
        auditVersion: {},
        loading:false
      })
      const $route = inject('$route')
      const initData = async () => {
        state.loading = true;
        let result = await getAppletEdition({ brand_id: $route.query.brand_id, app_id: $route.query.app_id,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:""  }).then(res => res.data).catch(error => error)
        state.auditVersion = result.data.auditVersion;
        state.releaseVersion = result.data.releaseVersion;
        state.loading = false;
      }
      onMounted(() => {
        initData()
      })
      const back = () => {
        $Modal.confirm({
          content: '确定要回退上一个版本么？',
          centered:true,
          onOk: async () => {
            let result = await miniappRevert({ release_id: state.releaseVersion.id ,miniapp_type:$route.query.miniapp_type?$route.query.miniapp_type:"" }).then(res => res.data).catch(error => error)
            $iscode(result, true)
            initData()
          }
        })
      }
      // 确定要发布么
      const release = () => {
        $Modal.confirm({
          content: '确定要发布该版本么？',
          centered:true,
          onOk: async () => {

          }
        })
      }
      return {
        ...toRefs(state),
        back,
        release
      }
    }
  }
</script>